
import { stateError } from '../api/errors';
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

const axiosCore = axios.create({
  baseURL: process.env.REACT_APP_QUALITY_MODULE_URL,
  timeout: 120000,
  headers: { 'Accept-Language': 'tr-TR' },

});

axiosCore.interceptors.request.use(function (config) {
  const token = secureLocalStorage.getItem('token');

  if (!config.url.includes('public')) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
});

class request {
  static async get(url = '', params = {}, headers = {}) {
    try {
      return await axiosCore.get(url, { params, headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static async post(url = '', body = {}, params = {}, headers = {}) {
    try {
      return await axiosCore.post(url, body, { params, headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static async put(url = '', body = {}, headers = {}) {
    if (Object.keys(body).length !== 0) {
      let params = this.setParams({ params: body });
      url = url + '?' + params;
    }

    try {
      return await axiosCore.put(url, body, { headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static async delete(url = '', data = {}, headers = {}) {
    try {
      return await axiosCore.delete(url, { data, headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static setParams({ url, params }) {
    return Object.entries(params)
      .map((e) => e.join('='))
      .join('&');
  }
}

export default request;
//update