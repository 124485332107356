/**
 * Store take long to be ready
 * https://stackoverflow.com/questions/65217815/redux-handling-really-large-state-object
 */
import { configureStore } from '@reduxjs/toolkit';
import CAselectedAgreementReducer from './agreement/customer/selectedAgreementSlice';
import CAnavigationReducer from './agreement/customer/navigationSlice';
import SAselectedAgreementReducer from './agreement/standard/selectedSASlice';
import SAnavigationReducer from './agreement/standard/navigationSlice';
import conditionSliceReducer from './agreement/conditionSlice';
import RAselectedAgreementReducer from './agreement/royalty/selectedRASlice';
import RAnavigationReducer from './agreement/royalty/navigationSlice';
import ARnavigationReducer from './agreement/autorenewal/ARnavigationSlice';
import InvoiceNavigationReducer from './invoice/invoiceSlice';
import InvoiceGatFilterNavigationReducer from './invoice/invoiceGatFilterSlice';
import InvoicesNavigationReducer from './invoice/invoicesSlice';
import agreementCommonReducer from './agreement/common/agreementCommonSlice';
import AutoRenewalReducer from './agreement/autorenewal/selectedARSlice';
import menuSliceReducer from './menu/menuSlice';
import clipboardSliceReducer from './agreement/common/clipboardSlice';
import MainUIReducer from './mainSlice';
import ConcessionFeeReducer from './concessionfee/concessionFeeSlice';
import clearSliceReducer from './clearSlice';
import SelectedQualityKPISlice from './qualityModule/selectedQualityKPISlice';
import QMnavigationReducer from './qualityModule/navigationSlice';
import QMWeightDefinitionsReducer from './qualityModule/weightDefinitionsSlice';

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 },
        serializableCheck: { warnAfter: 128 },
    }),
    reducer: {
        agreementCommon: agreementCommonReducer,
        CAselectedAgreement: CAselectedAgreementReducer,
        CAnavigation: CAnavigationReducer,
        SAselectedAgreement: SAselectedAgreementReducer,
        SAnavigation: SAnavigationReducer,
        condition: conditionSliceReducer,
        RAselectedAgreement: RAselectedAgreementReducer,
        RAnavigation: RAnavigationReducer,
        ARnavigation: ARnavigationReducer,
        AutoRenewal: AutoRenewalReducer,
        InvoiceNavigation: InvoiceNavigationReducer,
        InvoiceGatFilterNavigation: InvoiceGatFilterNavigationReducer,
        InvoicesNavigation: InvoicesNavigationReducer,
        menu: menuSliceReducer,
        clipboard: clipboardSliceReducer,
        clear: clearSliceReducer,
        MainUI: MainUIReducer,
        ConcessionFee: ConcessionFeeReducer,
        selectedQualityKPI: SelectedQualityKPISlice,
        QMnavigation: QMnavigationReducer,
        QMWeightDefinitions: QMWeightDefinitionsReducer,
    },
});
