import request from './request';
import * as paths from './paths';

class QualityModuleAPI {

  static qualityKpiList = async () => {
    return await request.get(paths.qualityKpiModule);
  };

  static qualityKpiSave = async (params) => {
    return await request.post(paths.qualityKpiModule + "/save" , params);
  };

  static qualityKpiUpdate = async (id,params) => {
    return await request.put(paths.qualityKpiModule + "/" +  id, params);
  };

  static qualityKpiById = async (id) => {
    return await request.get(paths.qualityKpiModule + "/" + id);
  };

  static copyConditionById = async (id) => {
    return await request.get("/api/qualityKpiConditionModule/copyConditionById/" + id);
  };

  static deleteConditionById = async (id) => {
    return await request.delete("/api/qualityKpiConditionModule/" + id);
  };
  static search = async (params) => {
    return await request.post(paths.qualityKpiModule + "/search" ,params);
  };

  static weightDefinitionsSearch = async (params) => {
    return await request.post(paths.qMWeightDefinitions + "/search" ,params);
  };

  static weightDefinitionsSave = async (params) => {
    return await request.post(paths.qMWeightDefinitions + "/save" , params);
  };

  static qualityModuleGetActualsData = async (params) => {
    console.log(params)
    const url=(paths.qmFlightController + '/getActuals');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetAirports = async (params) => {
    console.log(params)
    const url=(paths.qmFlightController + '/getAirports');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetAirlines = async (params) => {
    const url=(paths.qmFlightController + '/getAirlines/'+ params);
    console.log(url)
    console.log(params)
    
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetCompanies = async (params) => {
    console.log(params)
    const url=(paths.qmFlightController + '/getCompanies');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetFlightPairs = async (airportId, airlineId) => {
    const url=(paths.qmFlightController + '/getFlightPairs/' + airportId + '/' + airlineId);
    console.log(url)
    return await request.post(url);
  ///api/qmFlightController/search
  };

  static getAirportStats = async (airportId) => {
    const url=(paths.qmFlightController + '/getAirportStats/' + airportId);
    console.log(url)
    return await request.post(url);
  ///api/qmFlightController/search
  };



  static qualityModuleSaveActualsData = async (params) => {
    const url=(paths.qmActualController + '/save');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };


  static qualityModuleCompleteActualsData = async (flightLinkId) => {
    console.log(flightLinkId)
    const url=(paths.qmActualController + '/complete/'+ flightLinkId);
    return await request.post(url , flightLinkId);
  ///api/qmFlightController/search
  };



  static qualityModuleFlights = async (params) => {
    const url=(paths.qmFlightController + '/getFlights?airlineId='+params.airlineId+'&airportId='+params.airportId);
    return await request.post(url , params);
  ///api/qmFlightController/search
  };

  static qualityModuleFlightKPI = async (params) => {
    return await request.post(paths.qmFlightController + '/getFlightKpi?flightId='+params.flightId , params);
  ///api/qmFlightController/search
  };

  
  static qualityModuleFlightPairKPI = async (params) => {
    return await request.post(paths.qmFlightController + '/getFlightPairKpi?flightLinkId='+params.flightLinkId , params);
  ///api/qmFlightController/search
  };

}

export { QualityModuleAPI };
//update
//update